export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~3],
		"/(company)/about-us": [~44],
		"/(company)/about-us/team": [~45],
		"/(admin)/administration": [4,[2]],
		"/(admin)/administration/access": [5,[2]],
		"/(admin)/administration/access/roles": [~6,[2]],
		"/(admin)/administration/access/users": [~7,[2]],
		"/(admin)/administration/login": [8],
		"/(admin)/administration/news": [~9,[2]],
		"/(admin)/administration/news/new": [~11,[2]],
		"/(admin)/administration/news/[newsID]": [~10,[2]],
		"/(admin)/administration/objects": [12,[2]],
		"/(admin)/administration/objects/apartments": [~13,[2]],
		"/(admin)/administration/objects/apartments/[apartmentID]": [~14,[2]],
		"/(admin)/administration/objects/buildings": [~15,[2]],
		"/(admin)/administration/objects/buildings/[buildingID]": [~16,[2]],
		"/(admin)/administration/objects/genplans": [17,[2]],
		"/(admin)/administration/objects/genplans/GARDENHILLS": [~18,[2]],
		"/(admin)/administration/objects/genplans/GARDENHILLS/areas": [~19,[2]],
		"/(admin)/administration/objects/genplans/GARDENHILLS/areas/[buildingID]": [~20,[2]],
		"/(admin)/administration/objects/genplans/GARDENHILLS/areas/[buildingID]/areas": [~21,[2]],
		"/(admin)/administration/objects/genplans/GARDENHILLS/areas/[buildingID]/areas/[floorID]": [~22,[2]],
		"/(admin)/administration/objects/genplans/GARDENHILLS/areas/[buildingID]/areas/[floorID]/areas": [~23,[2]],
		"/(admin)/administration/objects/genplans/GARDENHILLS/canvas": [~24],
		"/(admin)/administration/objects/layouts": [~25,[2]],
		"/(admin)/administration/objects/layouts/[layoutID]": [~26,[2]],
		"/(admin)/administration/objects/projects": [27,[2]],
		"/(admin)/administration/objects/projects/GARDENHILLS": [~28,[2]],
		"/(admin)/administration/objects/projects/PRIVILEGIA": [~29,[2]],
		"/(admin)/administration/pages": [30,[2]],
		"/(admin)/administration/pages/about-us": [~31,[2]],
		"/(admin)/administration/pages/contacts": [~32,[2]],
		"/(admin)/administration/pages/for-partners": [33,[2]],
		"/(admin)/administration/pages/for-partners/procurements": [34,[2]],
		"/(admin)/administration/pages/for-partners/procurements/new": [36,[2]],
		"/(admin)/administration/pages/for-partners/procurements/[procurementID]": [~35,[2]],
		"/(admin)/administration/pages/for-partners/vacancies": [37,[2]],
		"/(admin)/administration/pages/for-partners/vacancies/new": [39,[2]],
		"/(admin)/administration/pages/for-partners/vacancies/[vacancyID]": [~38,[2]],
		"/(admin)/administration/pages/for-residents": [~40,[2]],
		"/(admin)/administration/pages/homepage": [~41,[2]],
		"/(admin)/administration/pages/payment": [~42,[2]],
		"/(admin)/administration/settings": [43,[2]],
		"/(company)/contacts": [~46],
		"/dev/area": [56],
		"/dev/icons": [57],
		"/dev/icons/[iconID]": [58],
		"/dev/pdf-designer": [59],
		"/(company)/find-apartment": [~47],
		"/(company)/for-partners": [48],
		"/(company)/for-residents": [~49],
		"/(company)/news": [~50],
		"/(company)/news/[articleID]": [~51],
		"/(company)/payment": [~52],
		"/(company)/projects": [~53],
		"/(company)/projects/GARDENHILLS": [~54],
		"/(company)/sale": [~55]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';